import { withTranslation } from "react-i18next";
import Login from "../Login";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import PrivateRoute from "../Hooks/PrivateRoute";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDataAsync } from "../Redux/userDataSlice";
import { styled, useTheme } from "@mui/material";
import BreadCrumbs from "../General/Component/BreadCrumps";
import { routes } from "../Dashboard/Constant/routes";
import Dashboard from "../Dashboard";
import * as route from "../General/Constant/routes";
import UserActivation from "../UserActivation";
import FailedActivation from "../FailedActivation";
import ResetPassword from "../ResetPassword";
import ChangePassword from "../ChangePassword";
import ExpiredPage from "../ExpiredPage";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: "32px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `50px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const App = (props) => {
  const theme = useTheme();
  const { t } = props;
  const userManage = useSelector((state) => state.user_data.userData);
  const [drawer, setDrawer] = useState([]);
  const [listRoute, setListRoute] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userManage) {
      dispatch(fetchUserDataAsync());
    } else if (userManage) {
      const drawerMenuPermission = userManage?.permissionMenu;
      setDrawer(drawerMenuPermission);
      const resultRouteList = routes(
        t,
        userManage && drawerMenuPermission,
        "/inapass"
      );

      setListRoute(resultRouteList);
    }
  }, [userManage, t, drawer, dispatch]);

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <>
            <Route
              path={route.USER_ACTIVATION}
              element={<UserActivation {...props} />}
            />
            <Route
              path={route.FAILED_ACTIVATION}
              element={<FailedActivation {...props} />}
            />
            <Route
              path={route.EXPIRED_PAGE}
              element={<ExpiredPage {...props} />}
            />
            <Route
              path={`${route.CHANGE_PASS}/:token`}
              element={<ChangePassword {...props} />}
            />
            <Route
              path={route.RESET_PASS}
              element={<ResetPassword {...props} />}
            />
            <Route path={route.LOGIN}>
              <Route index element={<Login {...props} />} />
              <Route element={<PrivateRoute />}>
                {userManage && drawer && listRoute.length
                  ? listRoute.map((el, key) => {
                      return (
                        <Route
                          key={key}
                          path={el.path}
                          element={
                            <>
                              <Dashboard
                                handleDrawerOpen={handleDrawerOpen}
                                setDrawerOpen={setDrawerOpen}
                                drawerOpen={drawerOpen}
                                listRoute={listRoute}
                              />
                              <Main theme={theme} open={drawerOpen}>
                                <DrawerHeader theme={theme} />
                                <BreadCrumbs route={el.navigationPath} />
                                <el.Component {...props} />
                              </Main>
                            </>
                          }
                        />
                      );
                    })
                  : null}

                <Route path="*" element={<h1>Page not found</h1>} />
              </Route>
            </Route>
          </>
        )
      )}
    />
  );
};

export default withTranslation("App")(App);
