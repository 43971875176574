import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RequestHttpGatewayInternal from "../General/Helper/RequestHttpGatewayInternal";
import Cookies from "js-cookie";

const initialState = {
  token: null,
  apiKey: null,
  userData: null,
  loading: false,
  error: null,
};

const userDataSlice = createSlice({
  name: "user_data",
  initialState,
  reducers: {
    fetchUserData(state, action) {
      state.userData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDataAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDataAsync.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Create async thunks for fetching data
export const fetchUserDataAsync = createAsyncThunk(
  "user_data/fetchUserDataAsync",
  async (_, thunkAPI) => {
    const accessToken = Cookies.get("access_token");
    const apikey = Cookies.get("X-Req-Inapass");
    let currentDate = new Date();
    const timestamp = Math.floor(currentDate.getTime() / 1000);

    if (accessToken) {
      try {
        const response = await RequestHttpGatewayInternal.get(
          `/user-access-merchant/profile`,
          {
            headers: {
              Authorization: `${accessToken}`,
              "X-Req-Inapass": apikey,
              timestamp: timestamp,
            },
          }
        );
        return response.data.data; // Return data from the response
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const { fetchUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
