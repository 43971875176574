import React from "react";
import classes from "./Styles/failed.module.css";
import { Typography } from "@mui/material";
import LanguageSettings from "../General/Component/LanguageSettings";
import strings from "../General/Constant/Locales/Strings/AppString";

const FailedActivation = (props) => {
  const { t } = props;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.divHeader}>
            <img
              src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
              className={classes.imageLogo}
              alt="logo"
            />
            <LanguageSettings />
          </div>
          <div className={classes.divContent}>
            <img
              src="https://res.cloudinary.com/pastisah-id/image/upload/v1719461976/Frame_53348_icvaam.png"
              alt="icon_failed"
              className={classes.sizeIcon}
            />
            <div>
              <Typography className={classes.textTitle}>
                {t(strings.textFailedActivation)}
              </Typography>
              <Typography className={classes.textSubtitle}>
                {t(strings.textSubtitleFailedActivation)}
              </Typography>
            </div>
          </div>
          <div className={classes.divFooter}>
            <Typography className={classes.textCopyright}>
              {t(strings.textCopyright)}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailedActivation;
