import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";
import classes from "../Styles/dashboard.module.css";
import strings from "../../General/Constant/Locales/Strings/AppString";
import { logOut } from "../../General/Helper/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserSettings = ({ t }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const userManage = useSelector((state) => state.user_data.userData);
  //get initials
  const nameArray = userManage?.fullname.split(" ");
  const initials = nameArray
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    {
      id: "edit_user",
      name: t(strings.textUserProfileSetting),
      classes: classes.textRegularGray14,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
        >
          <path
            d="M16.2365 15.4062C15.1657 13.5549 13.5154 12.2274 11.5896 11.5981C12.5422 11.031 13.2823 10.1669 13.6963 9.13844C14.1103 8.10999 14.1752 6.97409 13.8811 5.90516C13.587 4.83624 12.9502 3.89341 12.0684 3.22145C11.1866 2.5495 10.1086 2.18558 8.99998 2.18558C7.89134 2.18558 6.81335 2.5495 5.93156 3.22145C5.04976 3.89341 4.41293 4.83624 4.11884 5.90516C3.82476 6.97409 3.88968 8.10999 4.30366 9.13844C4.71763 10.1669 5.45775 11.031 6.41037 11.5981C4.48451 12.2267 2.83427 13.5542 1.76341 15.4062C1.72414 15.4703 1.6981 15.5415 1.68681 15.6158C1.67552 15.69 1.67922 15.7658 1.69769 15.8386C1.71616 15.9114 1.74902 15.9798 1.79435 16.0397C1.83967 16.0996 1.89653 16.1498 1.96157 16.1874C2.02662 16.225 2.09853 16.2491 2.17306 16.2585C2.24759 16.2678 2.32324 16.2621 2.39554 16.2417C2.46784 16.2213 2.53532 16.1867 2.59401 16.1398C2.65269 16.0929 2.7014 16.0348 2.73724 15.9687C4.06193 13.6794 6.40334 12.3125 8.99998 12.3125C11.5966 12.3125 13.938 13.6794 15.2627 15.9687C15.2986 16.0348 15.3473 16.0929 15.4059 16.1398C15.4646 16.1867 15.5321 16.2213 15.6044 16.2417C15.6767 16.2621 15.7524 16.2678 15.8269 16.2585C15.9014 16.2491 15.9733 16.225 16.0384 16.1874C16.1034 16.1498 16.1603 16.0996 16.2056 16.0397C16.2509 15.9798 16.2838 15.9114 16.3023 15.8386C16.3207 15.7658 16.3244 15.69 16.3131 15.6158C16.3019 15.5415 16.2758 15.4703 16.2365 15.4062ZM5.06248 7.24999C5.06248 6.47123 5.29341 5.70995 5.72607 5.06243C6.15872 4.41491 6.77368 3.91023 7.49316 3.61221C8.21264 3.31419 9.00435 3.23622 9.76815 3.38815C10.5319 3.54008 11.2335 3.91509 11.7842 4.46576C12.3349 5.01643 12.7099 5.71802 12.8618 6.48182C13.0137 7.24562 12.9358 8.03732 12.6378 8.75681C12.3397 9.47629 11.8351 10.0912 11.1875 10.5239C10.54 10.9566 9.77874 11.1875 8.99998 11.1875C7.95603 11.1864 6.95516 10.7712 6.21698 10.033C5.47879 9.29481 5.06359 8.29394 5.06248 7.24999Z"
            fill="#707070"
          />
        </svg>
      ),
    },
    {
      id: "logout",
      name: t(strings.textLogout),
      classes: classes.textError50,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
        >
          <path
            d="M8.4375 15.6875C8.4375 15.8367 8.37824 15.9798 8.27275 16.0852C8.16726 16.1907 8.02418 16.25 7.875 16.25H3.375C3.22582 16.25 3.08274 16.1907 2.97725 16.0852C2.87176 15.9798 2.8125 15.8367 2.8125 15.6875V3.3125C2.8125 3.16332 2.87176 3.02024 2.97725 2.91475C3.08274 2.80926 3.22582 2.75 3.375 2.75H7.875C8.02418 2.75 8.16726 2.80926 8.27275 2.91475C8.37824 3.02024 8.4375 3.16332 8.4375 3.3125C8.4375 3.46168 8.37824 3.60476 8.27275 3.71025C8.16726 3.81574 8.02418 3.875 7.875 3.875H3.9375V15.125H7.875C8.02418 15.125 8.16726 15.1843 8.27275 15.2898C8.37824 15.3952 8.4375 15.5383 8.4375 15.6875ZM16.148 9.10203L13.3355 6.28953C13.2299 6.18398 13.0868 6.12469 12.9375 6.12469C12.7882 6.12469 12.6451 6.18398 12.5395 6.28953C12.434 6.39508 12.3747 6.53823 12.3747 6.6875C12.3747 6.83677 12.434 6.97992 12.5395 7.08547L14.3923 8.9375H7.875C7.72582 8.9375 7.58274 8.99676 7.47725 9.10225C7.37176 9.20774 7.3125 9.35082 7.3125 9.5C7.3125 9.64918 7.37176 9.79226 7.47725 9.89775C7.58274 10.0032 7.72582 10.0625 7.875 10.0625H14.3923L12.5395 11.9145C12.434 12.0201 12.3747 12.1632 12.3747 12.3125C12.3747 12.4618 12.434 12.6049 12.5395 12.7105C12.6451 12.816 12.7882 12.8753 12.9375 12.8753C13.0868 12.8753 13.2299 12.816 13.3355 12.7105L16.148 9.89797C16.2003 9.84573 16.2418 9.78369 16.2701 9.7154C16.2984 9.64712 16.3129 9.57392 16.3129 9.5C16.3129 9.42608 16.2984 9.35288 16.2701 9.2846C16.2418 9.21631 16.2003 9.15427 16.148 9.10203Z"
            fill="#EB5757"
          />
        </svg>
      ),
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (selected) => {
    if (selected === "logout") {
      logOut();
      navigate("/");
      handleClose();
    }
  };

  const initialsNameCircle = () => {
    return (
      <Box className={classes.box1}>
        <Box className={classes.box2}>
          <Typography className={classes.textRegularWhite14}>
            {initials || "AZ"}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <Button
        className={classes.optionButton}
        aria-describedby={id}
        onClick={handleClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.3538 6.35375L8.35375 11.3538C8.30732 11.4002 8.25217 11.4371 8.19147 11.4623C8.13077 11.4874 8.06571 11.5004 8 11.5004C7.9343 11.5004 7.86923 11.4874 7.80853 11.4623C7.74783 11.4371 7.69269 11.4002 7.64625 11.3538L2.64625 6.35375C2.55243 6.25993 2.49973 6.13269 2.49973 6C2.49973 5.86732 2.55243 5.74007 2.64625 5.64625C2.74007 5.55243 2.86732 5.49973 3 5.49973C3.13269 5.49973 3.25993 5.55243 3.35375 5.64625L8 10.2931L12.6463 5.64625C12.6927 5.5998 12.7479 5.56295 12.8086 5.53781C12.8693 5.51267 12.9343 5.49973 13 5.49973C13.0657 5.49973 13.1308 5.51267 13.1915 5.53781C13.2521 5.56295 13.3073 5.5998 13.3538 5.64625C13.4002 5.69271 13.4371 5.74786 13.4622 5.80856C13.4873 5.86925 13.5003 5.93431 13.5003 6C13.5003 6.0657 13.4873 6.13076 13.4622 6.19145C13.4371 6.25215 13.4002 6.3073 13.3538 6.35375Z"
            fill="#707070"
          />
        </svg>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.popoverUserSetting}
        classes={{ paper: classes.paperUserSetting }}
      >
        <div className={classes.listItemUser}>
          <div className={classes.divUserName}>
            {initialsNameCircle()}
            <div className={classes.divRow}>
              <div>
                <Typography className={classes.textRegular14}>
                  {userManage.fullname || "Anonymous"}
                </Typography>

                <Typography className={classes.textSemiRegularGray14}>
                  {userManage.userRole.name || "Anonymous"}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.grayLine} />
          <List sx={{ padding: 0 }}>
            {options.map((data) => (
              <ListItemButton
                key={data.id}
                onClick={() => handleSelectOption(data.id)}
                selected={data.id === selectedOption}
                className={classes.itemUserButton}
              >
                <ListItemIcon sx={{ padding: 0, minWidth: "auto" }}>
                  {data.icon}
                </ListItemIcon>
                <ListItemText primary={data.name} className={data.classes} />
              </ListItemButton>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default UserSettings;
