import React, { useEffect, useState } from "react";
import classes from "./Styles/activation.module.css";
import { Button, Typography } from "@mui/material";
import LanguageSettings from "../General/Component/LanguageSettings";
import strings from "../General/Constant/Locales/Strings/AppString";
import { useLocation, useNavigate } from "react-router-dom";
import activationUser from "./Helper/activationUser";
import LoadingState from "../General/Component/LoadingState";

const UserActivation = (props) => {
  const { t } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = location.pathname.split("/")[2];
    activationUser(setLoading, token, navigate);
  }, [location.pathname]);

  return (
    <>
      {loading ? (
        <>
          <LoadingState />
        </>
      ) : (
        <>
          <div className={classes.root}>
            <div className={classes.container}>
              <div className={classes.divHeader}>
                <img
                  src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
                  className={classes.imageLogo}
                  alt="logo"
                />
                <LanguageSettings />
              </div>
              <div className={classes.divContent}>
                <img
                  src="https://res.cloudinary.com/pastisah-id/image/upload/v1719308876/35202596_party_popper_1_k4a3sr.png"
                  alt="icon_party"
                  className={classes.sizeIcon}
                />
                <div>
                  <Typography className={classes.textTitle}>
                    {t(strings.textSuccessActivation)}
                  </Typography>
                  <Typography className={classes.textSubtitle}>
                    {t(strings.textSubtitleActivation)}
                  </Typography>
                </div>
                <Button
                  onClick={() => navigate("/")}
                  className={classes.styleButton}
                >
                  <Typography className={classes.textButton}>
                    {t(strings.textGoToLogin)}
                  </Typography>
                </Button>
              </div>
              <div className={classes.divFooter}>
                <Typography className={classes.textCopyright}>
                  {t(strings.textCopyright)}
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserActivation;
