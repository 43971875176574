import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from "@mui/material";
import classes from "../Styles/general.module.css";
import strings from "../Constant/Locales/Strings/AppString";
import { withTranslation } from "react-i18next";

const TablePagination = ({
  t,
  rowsPerPage,
  currentPage,
  totalPages,
  handlePageChange,
  handleRowsPerPage,
}) => {
  const renderPageButtons = () => {
    if (totalPages <= 3) {
      return Array.from({ length: totalPages }, (_, index) => index + 1).map(
        (page) => (
          <Button
            key={page}
            onClick={() => handlePageChange(page)}
            className={
              currentPage === page
                ? classes.containedPaginationButton
                : classes.transparentPaginationButton
            }
          >
            {page}
          </Button>
        )
      );
    } else {
      return (
        <>
          <Button
            onClick={() => handlePageChange(1)}
            className={
              currentPage === 1
                ? classes.containedPaginationButton
                : classes.transparentPaginationButton
            }
          >
            1
          </Button>
          {currentPage > 2 && (
            <>
              <Button disabled className={classes.disabledButton}>
                ...
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                className={classes.transparentPaginationButton}
              >
                {currentPage - 1}
              </Button>
            </>
          )}
          {currentPage !== 1 && currentPage !== totalPages && (
            <Button
              onClick={() => handlePageChange(currentPage)}
              className={classes.containedPaginationButton}
            >
              {currentPage}
            </Button>
          )}
          {currentPage < totalPages - 1 && (
            <>
              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                className={classes.transparentPaginationButton}
              >
                {currentPage + 1}
              </Button>
              <Button disabled className={classes.disabledButton}>
                ...
              </Button>
            </>
          )}
          <Button
            onClick={() => handlePageChange(totalPages)}
            className={
              currentPage === totalPages
                ? classes.containedPaginationButton
                : classes.transparentPaginationButton
            }
          >
            {totalPages}
          </Button>
        </>
      );
    }
  };

  const CustomArrowIcon = (props) => (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M13.354 6.85378L8.35403 11.8538C8.30759 11.9003 8.25245 11.9372 8.19175 11.9623C8.13105 11.9875 8.06599 12.0004 8.00028 12.0004C7.93457 12.0004 7.86951 11.9875 7.80881 11.9623C7.74811 11.9372 7.69296 11.9003 7.64653 11.8538L2.64653 6.85378C2.55271 6.75996 2.5 6.63272 2.5 6.50003C2.5 6.36735 2.55271 6.2401 2.64653 6.14628C2.74035 6.05246 2.8676 5.99976 3.00028 5.99976C3.13296 5.99976 3.26021 6.05246 3.35403 6.14628L8.00028 10.7932L12.6465 6.14628C12.693 6.09983 12.7481 6.06298 12.8088 6.03784C12.8695 6.0127 12.9346 5.99976 13.0003 5.99976C13.066 5.99976 13.131 6.0127 13.1917 6.03784C13.2524 6.06298 13.3076 6.09983 13.354 6.14628C13.4005 6.19274 13.4373 6.24789 13.4625 6.30859C13.4876 6.36928 13.5006 6.43434 13.5006 6.50003C13.5006 6.56573 13.4876 6.63079 13.4625 6.69148C13.4373 6.75218 13.4005 6.80733 13.354 6.85378Z"
          fill="#3C3C3C"
        />
      </svg>
    </SvgIcon>
  );

  return (
    <Box className={classes.rowPagination}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        m={1}
        gap={2}
      >
        <Typography className={classes.textSemiBold14}>
          {t(strings.textShow)}
        </Typography>
        <FormControl className={classes.selectControl}>
          <Select
            disabled={totalPages === 0}
            value={rowsPerPage}
            onChange={handleRowsPerPage}
            IconComponent={CustomArrowIcon}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              ".MuiSelect-select": {
                padding: 0, // Remove padding here
              },
            }}
          >
            <MenuItem value={5} className={classes.textSemiBold14}>
              5
            </MenuItem>
            <MenuItem value={10} className={classes.textSemiBold14}>
              10
            </MenuItem>
            <MenuItem value={50} className={classes.textSemiBold14}>
              50
            </MenuItem>
          </Select>
        </FormControl>
        <Typography className={classes.textSemiBold14}>
          {t(strings.textPerPage)}
        </Typography>
      </Box>
      <Box className={classes.numberPagination}>
        <Button
          className={classes.outlinedPaginationButton}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || totalPages === 0}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M11.6484 14.727C11.7007 14.7792 11.7421 14.8413 11.7704 14.9096C11.7987 14.9779 11.8133 15.051 11.8133 15.1249C11.8133 15.1989 11.7987 15.272 11.7704 15.3403C11.7421 15.4086 11.7007 15.4707 11.6484 15.5229C11.5961 15.5752 11.5341 15.6166 11.4658 15.6449C11.3975 15.6732 11.3244 15.6878 11.2504 15.6878C11.1765 15.6878 11.1033 15.6732 11.0351 15.6449C10.9668 15.6166 10.9047 15.5752 10.8525 15.5229L5.22747 9.89792C5.17517 9.84567 5.13369 9.78364 5.10538 9.71535C5.07707 9.64706 5.0625 9.57387 5.0625 9.49995C5.0625 9.42603 5.07707 9.35283 5.10538 9.28454C5.13369 9.21626 5.17517 9.15422 5.22747 9.10198L10.8525 3.47698C10.958 3.37143 11.1012 3.31213 11.2504 3.31213C11.3997 3.31213 11.5429 3.37143 11.6484 3.47698C11.754 3.58253 11.8133 3.72568 11.8133 3.87495C11.8133 4.02421 11.754 4.16737 11.6484 4.27292L6.42068 9.49995L11.6484 14.727Z"
              fill="#3C3C3C"
            />
          </svg>
          {t(strings.textPrevious)}
        </Button>
        <div className={classes.divPaginationNumber}>{renderPageButtons()}</div>
        <Button
          className={classes.outlinedPaginationButton}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          {t(strings.textNext)}
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation("App")(TablePagination);
