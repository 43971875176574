import React, { useCallback, useEffect, useState } from "react";
import classes from "./Styles/changepass.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import { withTranslation } from "react-i18next";
import handleCloseNotif from "../General/Helper/handleCloseNotif";
import strings from "../General/Constant/Locales/Strings/AppString";
import LanguageSettings from "../General/Component/LanguageSettings";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import decryptData from "../General/Helper/decryptData";
import { jwtDecode } from "jwt-decode";
import combinationUpperLowerNumber from "../General/Helper/combinationUpperLowerNumber";
import textContainSymbol from "../General/Helper/textContainSymbol";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ChangePasswordUser } from "./Helper/http-request";

const ChangePassword = (props) => {
  const { t } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [dataNotification, setDataNotification] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [doneChange, setDoneChange] = useState(false);
  const [valueDecrypt, setValueDecrypt] = useState({
    email: "",
    jwt: "",
    merchant_id: "",
    user_id: "",
  });
  const [valuePass, setValuePass] = useState({
    password: "",
    retype_pass: "",
  });
  const [loading, setLoading] = useState(false);

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  const decodeJWT = useCallback((token) => {
    const decoded = jwtDecode(token);
    if (new Date() * 1 > decoded.exp * 1000) {
      console.log("masuk");
      navigate("/expired");
    }
  }, []);

  useEffect(() => {
    const token = location.pathname.split("/")[3];
    const result = JSON.parse(decryptData(token));
    // const apikey = encryptedSecretKey();
    setValueDecrypt({
      email: result.email,
      jwt: result.jwt,
      merchant_id: result.merchant_id,
      user_id: result.merchant_id,
    });
    if (result.jwt) {
      // Cookies.set("access_token", result.jwt);
      // Cookies.set("X-Req-Inapass", apikey);
      decodeJWT(result.jwt);
    }
  }, [location.pathname]);

  const InvalidIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1227 8.34581 17.266 6.28051 15.7427 4.75727C14.2195 3.23403 12.1542 2.37727 10 2.375ZM12.9422 12.5578C13.0003 12.6159 13.0463 12.6848 13.0777 12.7607C13.1092 12.8366 13.1254 12.9179 13.1254 13C13.1254 13.0821 13.1092 13.1634 13.0777 13.2393C13.0463 13.3152 13.0003 13.3841 12.9422 13.4422C12.8841 13.5003 12.8152 13.5463 12.7393 13.5777C12.6634 13.6092 12.5821 13.6253 12.5 13.6253C12.4179 13.6253 12.3366 13.6092 12.2607 13.5777C12.1848 13.5463 12.1159 13.5003 12.0578 13.4422L10 11.3836L7.94219 13.4422C7.88412 13.5003 7.81518 13.5463 7.73931 13.5777C7.66344 13.6092 7.58213 13.6253 7.5 13.6253C7.41788 13.6253 7.33656 13.6092 7.26069 13.5777C7.18482 13.5463 7.11588 13.5003 7.05782 13.4422C6.99975 13.3841 6.95368 13.3152 6.92226 13.2393C6.89083 13.1634 6.87466 13.0821 6.87466 13C6.87466 12.9179 6.89083 12.8366 6.92226 12.7607C6.95368 12.6848 6.99975 12.6159 7.05782 12.5578L9.11641 10.5L7.05782 8.44219C6.94054 8.32491 6.87466 8.16585 6.87466 8C6.87466 7.83415 6.94054 7.67509 7.05782 7.55781C7.17509 7.44054 7.33415 7.37465 7.5 7.37465C7.66586 7.37465 7.82492 7.44054 7.94219 7.55781L10 9.61641L12.0578 7.55781C12.1159 7.49974 12.1848 7.45368 12.2607 7.42225C12.3366 7.39083 12.4179 7.37465 12.5 7.37465C12.5821 7.37465 12.6634 7.39083 12.7393 7.42225C12.8152 7.45368 12.8841 7.49974 12.9422 7.55781C13.0003 7.61588 13.0463 7.68482 13.0777 7.76069C13.1092 7.83656 13.1254 7.91788 13.1254 8C13.1254 8.08212 13.1092 8.16344 13.0777 8.23931C13.0463 8.31518 13.0003 8.38412 12.9422 8.44219L10.8836 10.5L12.9422 12.5578Z"
          fill="#EB5757"
        />
      </svg>
    );
  };

  const StateIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1227 8.34581 17.266 6.28051 15.7427 4.75727C14.2195 3.23403 12.1542 2.37727 10 2.375ZM13.5672 9.06719L9.19219 13.4422C9.13415 13.5003 9.06522 13.5464 8.98934 13.5779C8.91347 13.6093 8.83214 13.6255 8.75 13.6255C8.66787 13.6255 8.58654 13.6093 8.51067 13.5779C8.43479 13.5464 8.36586 13.5003 8.30782 13.4422L6.43282 11.5672C6.31554 11.4499 6.24966 11.2909 6.24966 11.125C6.24966 10.9591 6.31554 10.8001 6.43282 10.6828C6.55009 10.5655 6.70915 10.4997 6.875 10.4997C7.04086 10.4997 7.19992 10.5655 7.31719 10.6828L8.75 12.1164L12.6828 8.18281C12.7409 8.12474 12.8098 8.07868 12.8857 8.04725C12.9616 8.01583 13.0429 7.99965 13.125 7.99965C13.2071 7.99965 13.2884 8.01583 13.3643 8.04725C13.4402 8.07868 13.5091 8.12474 13.5672 8.18281C13.6253 8.24088 13.6713 8.30982 13.7027 8.38569C13.7342 8.46156 13.7504 8.54288 13.7504 8.625C13.7504 8.70712 13.7342 8.78844 13.7027 8.86431C13.6713 8.94018 13.6253 9.00912 13.5672 9.06719Z"
          fill="#707070"
        />
      </svg>
    );
  };

  const ValidIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1227 8.34581 17.266 6.28051 15.7427 4.75727C14.2195 3.23403 12.1542 2.37727 10 2.375ZM13.5672 9.06719L9.19219 13.4422C9.13415 13.5003 9.06522 13.5464 8.98934 13.5779C8.91347 13.6093 8.83214 13.6255 8.75 13.6255C8.66787 13.6255 8.58654 13.6093 8.51067 13.5779C8.43479 13.5464 8.36586 13.5003 8.30782 13.4422L6.43282 11.5672C6.31554 11.4499 6.24966 11.2909 6.24966 11.125C6.24966 10.9591 6.31554 10.8001 6.43282 10.6828C6.55009 10.5655 6.70915 10.4997 6.875 10.4997C7.04086 10.4997 7.19992 10.5655 7.31719 10.6828L8.75 12.1164L12.6828 8.18281C12.7409 8.12474 12.8098 8.07868 12.8857 8.04725C12.9616 8.01583 13.0429 7.99965 13.125 7.99965C13.2071 7.99965 13.2884 8.01583 13.3643 8.04725C13.4402 8.07868 13.5091 8.12474 13.5672 8.18281C13.6253 8.24088 13.6713 8.30982 13.7027 8.38569C13.7342 8.46156 13.7504 8.54288 13.7504 8.625C13.7504 8.70712 13.7342 8.78844 13.7027 8.86431C13.6713 8.94018 13.6253 9.00912 13.5672 9.06719Z"
          fill="#4FBA77"
        />
      </svg>
    );
  };

  const listCondition = [
    {
      id: 1,
      text: t(strings.textMin8Chara),
    },
    {
      id: 2,
      text: t(strings.textCombinationTextandNumber),
    },
    {
      id: 3,
      text: t(strings.textAddSpecialChara),
    },
  ];

  const handleDisabledButton = () => {
    if (valuePass.password && valuePass.retype_pass) {
      if (valuePass.password !== valuePass.retype_pass) {
        return true;
      } else {
        if (
          valuePass.password.length >= 8 &&
          combinationUpperLowerNumber(valuePass.password) === true &&
          textContainSymbol(valuePass.password) === true
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const handleClassButton = () => {
    if (valuePass.password && valuePass.retype_pass) {
      if (valuePass.password !== valuePass.retype_pass) {
        return classes.inactiveButton;
      } else {
        if (
          valuePass.password.length >= 8 &&
          combinationUpperLowerNumber(valuePass.password) === true &&
          textContainSymbol(valuePass.password) === true
        ) {
          return classes.activeButton;
        } else {
          return classes.inactiveButton;
        }
      }
    } else {
      return classes.inactiveButton;
    }
  };

  const checkClassValidator = (idValidator) => {
    if (valuePass.password) {
      if (idValidator === 1 && valuePass.password.length >= 8) {
        return classes.textValidValidator;
      } else if (
        idValidator === 2 &&
        combinationUpperLowerNumber(valuePass.password)
      ) {
        return classes.textValidValidator;
      } else if (idValidator === 3 && textContainSymbol(valuePass.password)) {
        return classes.textValidValidator;
      } else {
        return classes.textInvalidValidator;
      }
    } else {
      return classes.textStateValidator;
    }
  };

  const checkIconValidator = (idValidator) => {
    if (valuePass.password) {
      if (idValidator === 1 && valuePass.password.length >= 8) {
        return ValidIcon();
      } else if (
        idValidator === 2 &&
        combinationUpperLowerNumber(valuePass.password)
      ) {
        return ValidIcon();
      } else if (idValidator === 3 && textContainSymbol(valuePass.password)) {
        return ValidIcon();
      } else {
        return InvalidIcon();
      }
    } else {
      return StateIcon();
    }
  };

  const changePass = () => {
    ChangePasswordUser(
      setLoading,
      valueDecrypt,
      valuePass,
      setDoneChange,
      setDataNotification
    );
  };

  return (
    <div className={classes.root}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />

      <div className={classes.container}>
        <div className={classes.divHeader}>
          <img
            src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
            className={classes.imageLogo}
            alt="logo"
          />
          <LanguageSettings />
        </div>
        {doneChange ? (
          <>
            <div className={classes.divFormSuccessChange}>
              <div>
                <img
                  src="https://res.cloudinary.com/pastisah-id/image/upload/v1725954651/Frame_53148_flwiwg.png"
                  className={classes.imageChangePassSuccess}
                  alt="success"
                />
              </div>
              <div>
                <Typography className={classes.textSuccessChangePass}>
                  {t(strings.textSuccessChangePass)}
                </Typography>
                <Typography className={classes.subTextSuccessChangePass}>
                  {t(strings.textUseNewPasswordToLogin)}
                </Typography>
              </div>
              <div>
                <Button
                  onClick={() => navigate("/")}
                  className={classes.btnGoToLoginPage}
                >
                  {t(strings.textGoToLoginPage)}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.divFormContainer}>
              <div>
                <Typography className={classes.title}>
                  {t(strings.textResetPassword)}
                </Typography>
              </div>
              <div>
                <Typography className={classes.labelemail}>
                  {t(strings.textNewPass)}
                </Typography>
                <TextField
                  className={classes.field}
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={valuePass.password}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.textField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setValuePass({ ...valuePass, password: e.target.value })
                  }
                />

                <Typography className={classes.labelemail}>
                  {t(strings.textRetypeNewPass)}
                </Typography>
                <TextField
                  className={classes.field}
                  variant="standard"
                  type={showPassword1 ? "text" : "password"}
                  name="password"
                  value={valuePass.retype_pass}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.textField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword1(!showPassword1)}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setValuePass({ ...valuePass, retype_pass: e.target.value })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                {listCondition.map((data) => {
                  return (
                    <div key={data.id} className={classes.rowValidator}>
                      <div>{checkIconValidator(data.id)}</div>
                      <Typography className={checkClassValidator(data.id)}>
                        {data.text}
                      </Typography>
                    </div>
                  );
                })}
              </div>
              <div>
                <Button
                  fullWidth
                  disabled={handleDisabledButton()}
                  className={handleClassButton()}
                  onClick={() => changePass()}
                >
                  {loading && (
                    <CircularProgress
                      size={"1rem"}
                      color="inherit"
                      className={classes.buttonProgress}
                    />
                  )}
                  {t(strings.textResetPassword)}
                </Button>
              </div>
            </div>
          </>
        )}

        <div className={classes.divFooter}>
          <Typography className={classes.textCopyright}>
            {t(strings.textCopyright)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("App")(ChangePassword);
