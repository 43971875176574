export const LOGIN = "/";
export const DASHBOARD = "/inapass/Dashboard";
export const USER_MANAGE = "/inapass/Roles";
export const USER_LIST = "/inapass/Roles/user_list";
export const USER_ROLE = "/inapass/Roles/user_role";
export const USER_ACTIVATION = "/activation/:token";
export const FAILED_ACTIVATION = "/failed_activation";
export const RESET_PASS = "/reset_password";
export const CHANGE_PASS = "/reset/password";
export const EXPIRED_PAGE = "/expired";
