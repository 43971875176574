import classes from "../Styles/usermanage.module.css";
import strings from "../../General/Constant/Locales/Strings/UserManageStrings";
import {
  Button,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import userRoleHeaders from "../Constant/UserRoleHeaders";
import { useEffect, useState } from "react";
import getAllUserRole from "../Helper/getAllUserRole";
import Cookies from "js-cookie";
import TablePagination from "../../General/Component/TablePagination";
import LoadingState from "../../General/Component/LoadingState";
import editUserRole from "../Helper/editUserRole";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import handleCloseNotif from "../../General/Helper/handleCloseNotif";
import { useSelector } from "react-redux";
import EditRoleDrawer from "../Component/EditRoleDrawer";
import SearchFilter from "../../General/Component/SearchFilter";
import ExportData from "../../General/Component/ExportData";
import CapitalizeFirstLetter from "../../General/Helper/capitalizeFirstLetter";
import { withTranslation } from "react-i18next";

const UserRole = (props) => {
  const { t } = props;
  const userManage = useSelector((state) => state.user_data.userData);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [dataNotification, setDataNotification] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [openExportData, setOpenExportData] = useState(false);
  const [userRoleEdit, setUserRoleEdit] = useState({
    roleName: "",
    idRole: 0,
    permissionAccess: [],
  });
  let isExport = false;
  const [merchantId, setMerchantId] = useState("");

  const checkedMenus = {
    Dashboard: false,
    "Transaction Activity": false,
    "Product Activity": false,
    "User List": false,
    "User Role": false,
    "Merchant Management": false,
    Roles: false,
    Provider: false,
    Threshold: false,
  };

  useEffect(() => {
    if (userManage) {
      setMerchantId(userManage.merchant);
    }
  }, userManage);

  const setAllFalse = (menus) => {
    for (let key in menus) {
      if (menus.hasOwnProperty(key)) {
        menus[key] = false;
      }
    }
  };

  useEffect(() => {
    if (merchantId) {
      getUserRole();
    }
  }, [merchantId]);

  const getUserRole = () => {
    getAllUserRole(
      searchValue,
      page,
      setTotalPage,
      rowsPerPage,
      Cookies.get("client_email"),
      setLoading,
      setUserRole,
      setDataNotification,
      isExport,
      null,
      setOpenExportData,
      merchantId
    );
  };

  const showUserRoleFilter = () => {
    return (
      <div className={classes.divFilter}>
        <SearchFilter
          t={t}
          searchValue={searchValue}
          handleSearchValue={handleSearchRoleName}
        />
        <ExportData
          openExportData={openExportData}
          handleOpenClose={setOpenExportData}
          handleExportData={handleExportData}
        />
      </div>
    );
  };

  const handleSearchRoleName = (event) => {
    setSearchValue(event.target.value);
    getAllUserRole(
      event.target.value,
      page,
      setTotalPage,
      rowsPerPage,
      Cookies.get("client_email"),
      setLoading,
      setUserRole,
      setDataNotification,
      isExport,
      null,
      setOpenExportData,
      merchantId
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    getAllUserRole(
      searchValue,
      newPage,
      setTotalPage,
      rowsPerPage,
      Cookies.get("client_email"),
      setLoading,
      setUserRole,
      setDataNotification,
      isExport,
      null,
      setOpenExportData,
      merchantId
    );
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllUserRole(
      searchValue,
      page,
      setTotalPage,
      event.target.value,
      Cookies.get("client_email"),
      setLoading,
      setUserRole,
      setDataNotification,
      isExport,
      null,
      setOpenExportData,
      merchantId
    );
  };

  const handleExportData = (exportType) => {
    isExport = true;
    getAllUserRole(
      searchValue,
      page,
      setTotalPage,
      rowsPerPage,
      Cookies.get("client_email"),
      setLoading,
      setUserRole,
      setDataNotification,
      isExport,
      exportType,
      setOpenExportData,
      merchantId
    );
  };

  const showUserRoleTable = () => {
    return (
      <TableContainer
        component={Paper}
        style={{
          width: openDialog ? "60%" : "100%",
          transition: "width 0.5s ease",
          overflow: "hidden",
          overflowX: "auto",
          boxShadow: "none",
        }}
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {userRoleHeaders(props).map((data) => {
                return (
                  <TableCell
                    key={data.id}
                    className={
                      data.id > 1 && data.id < userRoleHeaders(props).length
                        ? classes.tableCellFullBorder
                        : classes.tableRow
                    }
                  >
                    <Typography className={classes.textBold14}>
                      {data.label}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{printBodyData()}</TableBody>
        </Table>
        <TablePagination
          t={t}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          totalPages={totalPage}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
        />
      </TableContainer>
    );
  };

  const printBodyData = () => {
    if (loading) {
      return <LoadingState />;
    } else {
      if (userRole?.length > 0) {
        return userRole?.map((data) => {
          return (
            <TableRow key={data.id}>
              <TableCell className={classes.tableRow}>
                <Typography>{data.name}</Typography>
              </TableCell>
              {showAllMenu(data.permissionMenuUserAccessMerchant)}
              <TableCell className={classes.tableRow}>
                <Button
                  className={classes.editButton}
                  onClick={() => editRoleData(data)}
                >
                  {t(strings.textEditData)}
                </Button>
              </TableCell>
            </TableRow>
          );
        });
      } else {
        return (
          <TableRow>
            <TableCell colSpan={3} align="center" className={classes.tableRow}>
              {t(strings.textRecordsNotFound)}
            </TableCell>
          </TableRow>
        );
      }
    }
  };

  const showAllMenu = (permissions) => {
    return (
      <TableCell className={classes.tableCellFullBorder}>
        {permissions.map((data) => {
          if (data.is_access) {
            return (
              <List key={data.id} className={classes.divRow}>
                <Typography>{`${data.menu}`} </Typography>
                <Typography>
                  {showSubMenu(data.allowedSubMenuUserAccessMerchant)}
                </Typography>
              </List>
            );
          }
          return null;
        })}
      </TableCell>
    );
  };

  const showSubMenu = (allSubmenu) => {
    if (allSubmenu.length > 0) {
      let subTemp = [];

      allSubmenu.forEach((element) => {
        if (element.is_access && element.name !== "") {
          subTemp.push(element.name);
        }
      });

      if (subTemp.length > 0) {
        const mappedSubmenu = subTemp.map((data) => {
          if (data) {
            return CapitalizeFirstLetter(data.replace("_", " "));
          } else {
            return null;
          }
        });

        return `(${mappedSubmenu})`;
      }
    } else return null;
  };

  const editRoleData = (selectedRole) => {
    console.log(selectedRole, "P");
    setUserRoleEdit({
      idRole: selectedRole.id,
      roleName: selectedRole.name,
      permissionAccess: selectedRole.permissionMenuUserAccessMerchant.filter(
        (data) => data.is_access === true
      ),
    });
    setOpenDialog(true);
  };

  const handleChangeEditRole = (e, newValue) => {
    if (e?.target?.name) {
      setUserRoleEdit({ ...userRoleEdit, [e.target.name]: e.target.value });
    } else {
      setUserRoleEdit({
        ...userRoleEdit,
        permissionAccess: newValue.map((el) => {
          return { ...el, is_access: true };
        }), // Update permissionAccess with selected values
      });
    }
  };

  const handleCloseDialog = () => {
    setAllFalse(checkedMenus);
    setOpenDialog(false);
    setLoadingEdit(false);
  };

  const handleSubmitEditRole = () => {
    editUserRole(
      t,
      userRoleEdit,
      userRoleEdit.idRole,
      setLoadingEdit,
      setDataNotification,
      checkedMenus,
      handleCloseDialog,
      getUserRole,
      merchantId
    );
  };

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  return (
    <div className={classes.divContainer}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />
      <div>
        <Typography className={classes.textBold24}>
          {t(strings.textUserRole)}
        </Typography>
        <Typography className={classes.textGray16Color60}>
          {t(strings.textManageTeamRole)}
        </Typography>
      </div>

      <div className={classes.divTableData}>
        {showUserRoleFilter()}
        {showUserRoleTable()}
        <EditRoleDrawer
          t={t}
          loadingEdit={loadingEdit}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          userRoleEdit={userRoleEdit}
          handleChangeEditRole={handleChangeEditRole}
          handleSubmitEditRole={handleSubmitEditRole}
        />
      </div>
    </div>
  );
};

export default withTranslation("User")(UserRole);
