const userPermissionMenu = () => {
  return [
    {
      id: 1,
      menu: "Dashboard",
      isAccess: false,
    },
    {
      id: 2,
      menu: "Transaction Activity",
      isAccess: false,
    },
    {
      id: 3,
      menu: "Product Activity",
      isAccess: false,
    },
    {
      id: 5,
      menu: "User List",
      isAccess: false,
    },
    {
      id: 6,
      menu: "User Role",
      isAccess: false,
    },
  ];
};

export default userPermissionMenu;
