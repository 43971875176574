const strings = {
  textWelcome: "Welcome to INAPass Dashboard",
  textSubWelcome: "To get started, please login to your account",
  textEmailAddress: "E-mail Address",
  textPassword: "Password",
  textViewPassword: "View Password",
  textHidePassword: "Hide Password",
  textPlaceholderEmail: "name@domain.com",
  textPlaceholderPass: "Input your password",
  textPlaceholderMerchantID: "Input your merchant id",
  textPlaceholderPassword: "Type your password here...",
  textForgotPassword: "Forgot Password?",
  textLogin: "Login",
  textFooter1: "By Logging into your account, you agree to our",
  textFooter2: "Terms of",
  textFooter3: "Service",
  textFooter4: "and",
  textFooter5: "Privacy Policy",
  textCopyright: "©2024 - INAPass. All Rights Reserved.",
  textDashboard: "Dashboard",
  textUserManage: "User Management",
  textUserList: "User List",
  textUserRole: "User Role",
  textEkyc: "E-KYC",
  textProvider: "Provider",
  textThreshold: "Threshold",
  textPrevious: "Previous",
  textNext: "Next",
  textSettings: "Settings",
  textHelps: "Helps",
  textLogout: "Log Out",
  textUserProfileSetting: "User Profile Setting",
  textAllStatus: "All Status",
  textSuccessActivation: "Account Activation Successful!",
  textSubtitleActivation:
    "Please continue your activities by logging in to the INAPass dashboard using your account.",
  textGoToLogin: "Go to the Login Page",
  textMerchantManagement: "Merchant Management",
  textMerchant: "Merchant",
  textFailedActivation: "Account Activation Failed!",
  textSubtitleFailedActivation:
    "We're unable to complete the account verification at this time. Please try the verification process again. If the issue persists, reach out to the INAPass support team for assistance.",
  textTransactionActivity: "Transaction",
  textProductActivity: "Product Activities",
  textResetPassword: "Reset Password",
  textDescResetPassword:
    "Enter the email address you used to register. Next, we will send you instructions to reset your password.",
  textCheckEmail: "Check Your Email",
  textDescAfterSendEmail: "We have sent instructions to reset the password to",
  textDidntReceiveEmail: "Didn't receive the email?",
  textClickHereToResend: "Click here to resend.",
  textMin8Chara: "Minimum of 8 characters.",
  textCombinationTextandNumber:
    "Use a combination of uppercase letters (A-Z), lowercase letters (a-z), and Number.",
  textAddSpecialChara:
    "Incorporate special characters, such as symbols or punctuation (!, @, #, $, etc.).",
  textSuccessChangePass: "You have successfully changed your password!",
  textUseNewPasswordToLogin:
    "Use the new password to log in to your account. Remember, don't share your password with anyone!",
  textGoToLoginPage: "Go to Log In Page",
  textNewPass: "New Password",
  textRetypeNewPass: "Retype New Password",
  textPageUnavail: "Page is no longer available!",
  textLinkExpired:
    "The link you are trying to access may have expired. Please return to the home page to continue your activity. If you feel this is an error, please do not hesitate to contact our support team.",
  //invalid text
  textInvalidEmail: "Invalid e-mail format",
  textAllProducts: "All Products",

  //days
  textSunday: "Sunday",
  textMonday: "Monday",
  textTuesday: "Tuesday",
  textWednesday: "Wednesday",
  textThursday: "Thursday",
  textFriday: "Friday",
  textSaturday: "Saturday",

  //month
  textJanuary: "January",
  textFebruary: "February",
  textMarch: "March",
  textApril: "April",
  textMay: "May",
  textJune: "June",
  textJuly: "July",
  textAugust: "August",
  textSeptember: "September",
  textOctober: "October",
  textNovember: "November",
  textDecember: "December",
  textMerchantID: "Merchant ID",

  textMerchantIDRequired: "Merchant ID is Required",
  textEmailRequired: "Email is Required",
  textPassRequired: "Password is Required",
  textShow: "Show",
  textPerPage: "per page",
};

export default strings;
