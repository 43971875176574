import onSubmitLogin from "./onSubmitLogin";

const validateLogin = (
  email,
  password,
  merchantId,
  setDataNotification,
  setLoading,
  navigate,
  dispatch,
  t,
  strings
) => {
  let error = 0;
  if (email) {
  } else {
    error = error + 1;
    setDataNotification({
      open: true,
      status: "error",
      message: t(strings.textEmailRequired),
    });
  }

  if (password) {
  } else {
    error = error + 1;
    setDataNotification({
      open: true,
      status: "error",
      message: t(strings.textPassRequired),
    });
  }

  if (merchantId) {
  } else {
    error = error + 1;
    setDataNotification({
      open: true,
      status: "error",
      message: t(strings.textMerchantIDRequired),
    });
  }

  if (error === 0) {
    return onSubmitLogin(
      email,
      password,
      merchantId,
      setLoading,
      setDataNotification,
      navigate,
      dispatch
    );
  }
};

export default validateLogin;
