import encryptedSecretKey from "../../General/Helper/encryptedSecretKey";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";

const ChangePasswordUser = async (
  setLoading,
  valueDecrypt,
  valuePass,
  setDoneChange,
  setDataNotification
) => {
  try {
    setLoading(true);
    const body = {
      email: valueDecrypt.email,
      merchantId: valueDecrypt.merchant_id,
      password: valuePass.password,
    };
    const result = await RequestHttpGatewayInternal({
      method: "POST",
      url: "/user-access-merchant/change-password",
      headers: {
        Authorization: valueDecrypt.jwt,
        "X-Req-Inapass": encryptedSecretKey(),
        timestamp: Math.floor(new Date().getTime() / 1000),
      },
      data: body,
    });
    if (result.data) {
      setLoading(false);
      setDoneChange(true);
    }
  } catch (error) {
    setLoading(false);
    setDataNotification({
      open: true,
      status: "error",
      message: error.response.data.response_message,
    });
  }
};

export { ChangePasswordUser };
