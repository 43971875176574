import Cookies from "js-cookie";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import strings from "../../General/Constant/Locales/Strings/UserManageStrings";

const editUserRole = (
  t,
  userRoleEdit,
  idRole,
  setLoadingEdit,
  setDataNotification,
  checkedMenus,
  handleCloseDialog,
  getUserRole,
  merchantId
) => {
  setLoadingEdit(true);

  userRoleEdit.permissionAccess.forEach((element) => {
    const key = element.menu;
    checkedMenus[key] = true;
  });

  const permission_user_access = [
    {
      menu: "Dashboard",
      is_access: checkedMenus.Dashboard,
      allowed_sub_menu: [],
    },
    {
      menu: "Transaction Activity",
      is_access: checkedMenus["Transaction Activity"],
      allowed_sub_menu: [],
    },
    {
      menu: "Product Activity",
      is_access: checkedMenus["Product Activity"],
      allowed_sub_menu: [],
    },
    {
      menu: "Roles",
      is_access: checkedMenus["User Role"] || checkedMenus["User List"],
      allowed_sub_menu: [
        {
          name: "User List",
          is_access: checkedMenus["User List"],
        },
        {
          name: "User Role",
          is_access: checkedMenus["User Role"],
        },
      ],
    },
    {
      menu: "Merchant Management",
      is_access: checkedMenus["Merchant Management"],
      allowed_sub_menu: [],
    },

    {
      menu: "KYC",
      is_access: checkedMenus["Provider"] || checkedMenus["Threshold"],
      allowed_sub_menu: [
        {
          name: "Provider",
          is_access: checkedMenus["Provider"],
        },
        {
          name: "Threshold",
          is_access: checkedMenus["Threshold"],
        },
      ],
    },
  ];

  const currentDate = new Date();
  const timestamp = Math.floor(currentDate.getTime() / 1000);

  const requestBody = {
    id: Number(idRole),
    merchantId: merchantId,
    permissions: permission_user_access,
    rolesName: userRoleEdit.roleName,
  };

  RequestHttpGatewayInternal.put(`user-access-merchant/roles`, requestBody, {
    headers: {
      Authorization: Cookies.get("access_token"),
      "X-Req-Inapass": Cookies.get("X-Req-Inapass"),
      timestamp: timestamp,
    },
  })
    .then((res) => {
      setDataNotification({
        open: true,
        status: "success",
        message: t(strings.textSuccessEditPermission),
      });
      getUserRole();
      handleCloseDialog();
    })
    .catch((err) => {
      setDataNotification({
        open: true,
        status: "error",
        message: err.response.data.response_message,
      });
    })
    .finally(() => {
      setLoadingEdit(false);
    });
};

export default editUserRole;
