import React, { useState } from "react";
import classes from "./Styles/reset.module.css";
import { useNavigate } from "react-router-dom";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import emailValidation from "../General/Helper/emailValidation";
import { withTranslation } from "react-i18next";
import handleCloseNotif from "../General/Helper/handleCloseNotif";
import strings from "../General/Constant/Locales/Strings/AppString";
import LanguageSettings from "../General/Component/LanguageSettings";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { sendEmailForgotPass } from "./Helper/http-request";

const ResetPass = (props) => {
  const { t } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [dataNotification, setDataNotification] = useState(null);
  const [doneSend, setDoneSend] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  const forgotPass = (trigger) => {
    sendEmailForgotPass(
      email,
      merchantId,
      setDoneSend,
      setLoading,
      trigger,
      setDataNotification
    );
  };

  return (
    <div className={classes.root}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />

      <div className={classes.container}>
        <div className={classes.divHeader}>
          <img
            src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
            className={classes.imageLogo}
            alt="logo"
          />
          <LanguageSettings />
        </div>
        <div className={classes.divFormContainer}>
          <IconButton onClick={() => navigate("/")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15.5302 18.9694C15.5999 19.039 15.6552 19.1218 15.6929 19.2128C15.7306 19.3039 15.75 19.4014 15.75 19.5C15.75 19.5985 15.7306 19.6961 15.6929 19.7872C15.6552 19.8782 15.5999 19.9609 15.5302 20.0306C15.4606 20.1003 15.3778 20.1556 15.2868 20.1933C15.1957 20.231 15.0982 20.2504 14.9996 20.2504C14.9011 20.2504 14.8035 20.231 14.7124 20.1933C14.6214 20.1556 14.5387 20.1003 14.469 20.0306L6.96899 12.5306C6.89926 12.461 6.84394 12.3782 6.80619 12.2872C6.76845 12.1961 6.74902 12.0986 6.74902 12C6.74902 11.9014 6.76845 11.8038 6.80619 11.7128C6.84394 11.6217 6.89926 11.539 6.96899 11.4694L14.469 3.96936C14.6097 3.82863 14.8006 3.74957 14.9996 3.74957C15.1986 3.74957 15.3895 3.82863 15.5302 3.96936C15.671 4.1101 15.75 4.30097 15.75 4.49999C15.75 4.69901 15.671 4.88988 15.5302 5.03061L8.55993 12L15.5302 18.9694Z"
                fill="#847A7C"
              />
            </svg>
          </IconButton>
          {doneSend ? (
            <>
              <div>
                <Typography className={classes.title}>
                  {t(strings.textCheckEmail)}
                </Typography>
                <Typography className={classes.desctitle}>
                  {t(strings.textDescAfterSendEmail) + " " + email}
                </Typography>
              </div>
              <div>
                <Typography>
                  <span className={classes.textDidntReceiveEmail}>
                    {t(strings.textDidntReceiveEmail)}
                  </span>{" "}
                  <span
                    onClick={() => forgotPass("2")}
                    className={classes.textClickHereToResend}
                  >
                    {t(strings.textClickHereToResend)}
                  </span>
                </Typography>
              </div>
            </>
          ) : (
            <>
              <div>
                <Typography className={classes.title}>
                  {t(strings.textResetPassword)}
                </Typography>
                <Typography className={classes.desctitle}>
                  {t(strings.textDescResetPassword)}
                </Typography>
              </div>
              <div>
                <Typography className={classes.labelemail}>
                  Merchant ID
                </Typography>
                <TextField
                  className={classes.field}
                  variant="standard"
                  type="email"
                  name="email"
                  value={merchantId}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.textField,
                  }}
                  onChange={(e) => setMerchantId(e.target.value)}
                  // placeholder="Name@domain.com"
                />
                {email ? (
                  emailValidation(email) ? (
                    <Typography className={classes.labelemail}>
                      Email
                    </Typography>
                  ) : (
                    <Typography className={classes.labelemailInvalid}>
                      Email
                    </Typography>
                  )
                ) : (
                  <Typography className={classes.labelemail}>Email</Typography>
                )}

                <TextField
                  className={classes.field}
                  variant="standard"
                  type="email"
                  name="email"
                  value={email}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.textField,
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Name@domain.com"
                />
                {email ? (
                  emailValidation(email) ? (
                    ""
                  ) : (
                    <Typography className={classes.textAlert12}>
                      {t(strings.textInvalidEmail)}
                    </Typography>
                  )
                ) : (
                  ""
                )}
              </div>

              {email ? (
                emailValidation(email) ? (
                  <Button
                    onClick={() => forgotPass("1")}
                    disabled={loading}
                    className={
                      loading ? classes.disabledBtn : classes.activeBtn
                    }
                  >
                    {loading ? <CircularProgress size={"1em"} /> : null}
                    {t(strings.textResetPassword)}
                  </Button>
                ) : (
                  <Button disabled={true} className={classes.disabledBtn}>
                    {t(strings.textResetPassword)}
                  </Button>
                )
              ) : (
                <Button disabled={true} className={classes.disabledBtn}>
                  {t(strings.textResetPassword)}
                </Button>
              )}
            </>
          )}
        </div>
        <div className={classes.divFooter}>
          <Typography className={classes.textCopyright}>
            {t(strings.textCopyright)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("App")(ResetPass);
