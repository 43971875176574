import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import encryptedSecretKey from "../../General/Helper/encryptedSecretKey";

const activationUser = (setLoading, token, navigate) => {
  setLoading(true);
  const currentDate = new Date();
  const timestampInSeconds = Math.floor(currentDate.getTime() / 1000);
  const x_req_apikey = encryptedSecretKey();
  const body = {
    token_activation: token,
  };
  RequestHttpGatewayInternal.post(`/user-access-merchant/activation`, body, {
    headers: {
      "X-Req-Inapass": x_req_apikey,
      timestamp: timestampInSeconds,
    },
  })
    .then((_response) => {
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (!error?.response?.data?.response_message.includes("already active")) {
        navigate("/failed_activation");
      }
    });
};

export default activationUser;
