import React from "react";
import classes from "./Styles/expired.module.css";
import { Typography } from "@mui/material";
import LanguageSettings from "../General/Component/LanguageSettings";
import strings from "../General/Constant/Locales/Strings/AppString";

const ExpiredPage = (props) => {
  const { t } = props;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.divHeader}>
            <img
              src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
              className={classes.imageLogo}
              alt="logo"
            />
            <LanguageSettings />
          </div>
          <div className={classes.divContent}>
            <div className={classes.divBody}>
              <img
                src="https://res.cloudinary.com/pastisah-id/image/upload/v1726122997/No_data-bro_1_q9rl6l.png"
                alt="icon"
                className={classes.sizeIcon}
              />
            </div>
            <div className={classes.divBodyRight}>
              <div style={{ width: "400px" }}>
                <Typography className={classes.textTitle}>
                  {t(strings.textPageUnavail)}
                </Typography>
                <Typography className={classes.textSubtitle}>
                  {t(strings.textLinkExpired)}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.divFooter}>
            <Typography className={classes.textCopyright}>
              {t(strings.textCopyright)}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpiredPage;
