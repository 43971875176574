import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import classes from "./Style/login.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import handleCloseNotif from "../General/Helper/handleCloseNotif";
import validateLogin from "./Helper/validateLogin";
import emailValidation from "../General/Helper/emailValidation";
import { withTranslation } from "react-i18next";
import strings from "../General/Constant/Locales/Strings/AppString";
import { useDispatch } from "react-redux";
import LanguageSettings from "../General/Component/LanguageSettings";

const Login = (props) => {
  const { t } = props;
  const navigate = useNavigate();
  const [dataNotification, setDataNotification] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [typePassword, setTypePassword] = useState("password");
  const [loading, setLoading] = useState(false);
  const [dataLogin, setDataLogin] = useState({
    email: "",
    password: "",
    merchantId: "",
  });

  const dispatch = useDispatch();

  const handleChangeLogin = (e) => {
    setDataLogin({ ...dataLogin, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    if (typePassword === "password") {
      setTypePassword("text");
    } else if (typePassword === "text") {
      setTypePassword("password");
    }
  };

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  const handleLoginUser = () => {
    validateLogin(
      dataLogin.email,
      dataLogin.password,
      dataLogin.merchantId,
      setDataNotification,
      setLoading,
      navigate,
      dispatch,
      t,
      strings
    );
  };

  const handleLoginButton = () => {
    if (
      !dataLogin.email ||
      !dataLogin.password ||
      !emailValidation(dataLogin.email) ||
      !dataLogin.merchantId ||
      loading
    ) {
      return true;
    } else return false;
  };

  const handleLoginClasses = () => {
    if (
      !dataLogin.email ||
      !dataLogin.password ||
      !emailValidation(dataLogin.email) ||
      !dataLogin.merchantId ||
      loading
    ) {
      return classes.disabledContainedButton;
    } else return classes.containedButton;
  };

  const handleButtonText = () => {
    if (
      !dataLogin.email ||
      !dataLogin.password ||
      !emailValidation(dataLogin.email) ||
      loading
    ) {
      return classes.textDisabledContainedButton;
    } else return classes.textContainedButton;
  };

  const showFormField = () => {
    return (
      <div className={classes.divForm}>
        <div className={classes.divFormField}>
          <Typography className={classes.textRegular16}>
            {t(strings.textEmailAddress)}
          </Typography>
          <TextField
            // fullWidth
            className={classes.field}
            variant="standard"
            type="email"
            name="email"
            value={dataLogin.email}
            InputProps={{
              disableUnderline: true,
              className: classes.textField,
            }}
            placeholder={t(strings.textPlaceholderEmail)}
            onChange={handleChangeLogin}
          />
          {dataLogin.email ? (
            emailValidation(dataLogin.email) ? (
              ""
            ) : (
              <Typography className={classes.textAlert12}>
                {t(strings.textInvalidEmail)}
              </Typography>
            )
          ) : (
            ""
          )}
        </div>

        <div className={classes.divFormField}>
          <Typography className={classes.textRegular16}>
            {t(strings.textPassword)}
          </Typography>
          <TextField
            // fullWidth
            className={classes.field}
            variant="standard"
            type={typePassword}
            name="password"
            value={dataLogin.password}
            InputProps={{
              disableUnderline: true,
              className: classes.textField,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      typePassword === "password"
                        ? t(strings.textViewPassword)
                        : t(strings.textHidePassword)
                    }
                    componentsProps={{
                      tooltip: { className: classes.tooltipStyle },
                    }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <Visibility className={classes.visibilityIcon} />
                      ) : (
                        <VisibilityOff className={classes.visibilityIcon} />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            placeholder={t(strings.textPlaceholderPass)}
            onChange={handleChangeLogin}
          />
        </div>

        <div className={classes.divFormField}>
          <Typography className={classes.textRegular16}>
            {t(strings.textMerchantID)}
          </Typography>
          <TextField
            // fullWidth
            className={classes.field}
            variant="standard"
            type="text"
            name="merchantId"
            value={dataLogin.merchantId}
            InputProps={{
              disableUnderline: true,
              className: classes.textField,
            }}
            placeholder={t(strings.textPlaceholderMerchantID)}
            onChange={handleChangeLogin}
          />
        </div>

        <Link to={"/reset_password"} className={classes.textLinkPrimary16}>
          {t(strings.textForgotPassword)}
        </Link>

        <Button
          fullWidth
          className={handleLoginClasses()}
          onClick={handleLoginUser}
          disabled={handleLoginButton()}
        >
          {loading ? <CircularProgress size={"1em"} /> : null}
          <Typography className={handleButtonText()}>
            {t(strings.textLogin)}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />

      <div className={classes.container}>
        <div className={classes.divHeader}>
          <img
            src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
            className={classes.imageLogo}
            alt="logo"
          />
          <LanguageSettings />
        </div>
        <div className={classes.divFormContainer}>
          <div>
            <Typography className={classes.textRegular24}>
              {t(strings.textWelcome)}
            </Typography>
            <Typography className={classes.textRegular18}>
              {t(strings.textSubWelcome)}
            </Typography>
          </div>

          {showFormField()}

          <div>
            <span className={classes.textRegular14}>
              {t(strings.textFooter1)}
            </span>{" "}
            <Link
              to={"https://pastisah.id/"}
              target="blank"
              className={classes.textLinkRegular14}
            >
              {t(strings.textFooter2)} <br /> {t(strings.textFooter3)}
            </Link>{" "}
            <span className={classes.textRegular14}>
              {t(strings.textFooter4)}
            </span>{" "}
            <Link
              to={"https://pastisah.id/"}
              target="blank"
              className={classes.textLinkRegular14}
            >
              {t(strings.textFooter5)}
            </Link>
            <span className={classes.textRegular14}>.</span>
          </div>
        </div>
      </div>
      <div className={classes.divFooter}>
        <Typography className={classes.textGray14}>
          {t(strings.textCopyright)}
        </Typography>
      </div>
    </div>
  );
};

export default withTranslation("App")(Login);
