import Cookies from "js-cookie";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import exportFromJSON from "export-from-json";

const getAllUserRole = (
  searchValue,
  page,
  setTotalPage,
  rowsPerPage,
  userEmail,
  setLoading,
  setUserRole,
  setDataNotification,
  isExport,
  exportType,
  setOpenExportData,
  merchantId
) => {
  let currentDate = new Date();
  const timestamp = Math.floor(currentDate.getTime() / 1000);
  RequestHttpGatewayInternal.get(
    `user-access-merchant/roles?page=${page}&limit=${rowsPerPage}&email=${userEmail}&merchantId=${merchantId}&search=${
      searchValue || ""
    }&isExport=${isExport}&order=DESC`,
    {
      headers: {
        Authorization: Cookies.get("access_token"),
        "X-Req-Inapass": Cookies.get("X-Req-Inapass"),
        timestamp: timestamp,
      },
    }
  )
    .then((res) => {
      if (isExport) {
        const data = res.data.data;
        const fileName = "User Roles";
        exportFromJSON({ data, fileName, exportType });
      } else {
        const result = res.data.data;
        setUserRole(result.items);
        setTotalPage(result.meta.totalPages);
      }
    })
    .catch((err) => {
      setDataNotification({
        open: true,
        status: "error",
        message: err.response.data.response_message,
      });
    })
    .finally(() => {
      setLoading(false);
      setOpenExportData(false);
    });
};

export default getAllUserRole;
