const strings = {
  textUserList: "User List",
  textUserRole: "User Role",
  textManageTeam: "Manage your team members here.",
  textManageTeamRole:
    "Manage your team members and their account permission here.",

  textFullName: "Full Name",
  textSubtitleManage:
    "Manage your merchant management and their provider here.",
  textCompany: "Company",
  textEmail: "Email",
  textPhoneNumber: "Phone Number",
  textAddress: "Address",
  textStatus: "Status",
  textAction: "Action",
  textRoleName: "Role Name",
  textUserAccessPermission: "User Access Permission",
  textEditData: "Edit Data",
  textRecordsNotFound: "Records Not Found",
  textEditUserRole: "Edit User Access",
  textEditUserRoleSubtitle:
    "Update your team member data and their access permissions.",
  textDashboard: "Dashboard",
  textUserManagement: "User Management",
  textTransactionActivity: "Transaction Activity",
  textProductActivity: "Product Activity",
  textMerchantManagement: "Merchant Management",
  textProvider: "Provider",
  textRoles: "Roles",
  textRole: "Role",
  textSuccessEditPermission: "Successfully edit the access permission",
  textSuccessAddUser: "Successfully add user access",
  textPassword: "Password",
  textViewPassword: "View Password",
  textHidePassword: "Hide Password",
  textSearch: "Search",

  textPlaceholderFullName: "Input your full name",
  textPlaceholderEmail: "Input your email",
  textPlaceholderPassword: "Input your Password",
  textPlaceholderPhone: "812345690",
  textPlaceholderAddress: "Input your address",
  textPlaceholderRoleName: "Input your role name",
  textActive: "Active",
  textInactive: "Inactive",
  textSuspend: "Suspend",
  textAddNewUser: "Add New User",
  textUserRegistrationAccount: "User Registration Account",
  textEditUserData: "Edit User Data",
  textAddUserSubtitle:
    "Please complete your data below to register an account.",
  textRegisterAccount: "Register Account",
  textSuperAdmin: "Super Administrator",
  textCustom: "Custom",
  textAllStatus: "All Status",
  textPlaceholderSelectRole: "Select your role",

  //validation
  textRoleNameIsRequired: "Role Name is Required",
  textFullNameIsRequired: "Full Name is Required",
  textEmailIsRequired: "Email is Required",
  textPasswordIsRequired: "Password is Required",

  //invalid text
  textInvalidEmail: "Invalid e-mail format",
  textInvalidPhoneNumber: "Invalid phone number format",
  textInvalidPhoneNumberLength: "Phone number must be less than 13 digits",
};

export default strings;
