import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import AppID from "./General/Constant/Locales/ID/App.json";
import AppEN from "./General/Constant/Locales/EN/App.json";

import TransactionID from "./General/Constant/Locales/ID/Transaction.json";
import TransactionEN from "./General/Constant/Locales/EN/Transaction.json";

import UserID from "./General/Constant/Locales/ID/User.json";
import UserEN from "./General/Constant/Locales/EN/User.json";

const resources = {
  id: {
    App: AppID,
    Transaction: TransactionID,
    User: UserID,
  },
  en: {
    App: AppEN,
    Transaction: TransactionEN,
    User: UserEN,
  },
};

const localLang = localStorage.getItem("lang");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localLang ? localLang : "id", // by default id

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
