import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const sendEmailForgotPass = async (
  email,
  merchantId,
  setDoneSend,
  setLoading,
  trigger,
  setDataNotification
) => {
  setLoading(true);
  const body = {
    email: email,
    merchantId: merchantId?.toUpperCase(),
  };
  try {
    const result = await RequestHttpGatewayInternal({
      method: "POST",
      url: "/user-access-merchant/forgot-password",
      headers: getAuthenticatedServerRequest(),
      data: body,
    });
    if (result.data) {
      if (trigger === "1") {
        setDoneSend(true);
        setLoading(false);
      } else {
        setDataNotification({
          open: true,
          status: "success",
          message: result.data.response_message,
        });
      }
    }
  } catch (error) {
    setLoading(false);
    setDataNotification({
      open: true,
      status: "error",
      message: error.response.data.response_message,
    });
  }
};

export { sendEmailForgotPass };
